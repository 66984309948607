<template>
  <div id="graduates" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="groupedGraduates" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          groupedGraduates.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Graduates" v-slot="props"
          >{{ props.row.term_title }} Graduates</b-table-column
        >

        <b-table-column label="Number of Students" v-slot="props">{{
          props.row.number_of_students
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            :show-update="false"
            :show-remove="false"
          />
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'graduates',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Graduates',
      groupedGraduates: [],
    }
  },
  methods: {
    initializeInfo(graduate) {
      this.$router.push(
        `/school/${this.schoolId}/graduates_info/${graduate.term_id}`
      )
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Graduate Class Groups',
        route: `/school/${this.schoolId}/graduate_class_groups`,
      },
    ])
    this.$apollo.addSmartQuery('groupedGraduates', {
      query: gql`
        query GroupedGraduatesQuery($schoolId: ID!) {
          groupedGraduates(schoolId: $schoolId)
        }
      `,
      variables: {
        schoolId: this.schoolId,
      },
    })
    this.$apollo.queries.groupedGraduates.refetch()
  },
}
</script>
